<template>
    <v-container fluid class="mt-0 pa-0">
	<v-card class="page-menu mb-3" rounded="0"  flat>
		<v-sheet
			elevation="2"
			rounded="0"
			height="40"
			max-width="100%"
			class="white--text light-blue darken-4 pt-2 pl-3 ml-0 mb-2"
		>
			<v-icon class="white--text mb-2">mdi-database</v-icon>
			マスタ管理・メンテナンス 
		</v-sheet>
		<v-card class="ma-5 rounded" >
			<v-system-bar color="gray" height="50">
				<v-card-subtitle class="text-h6 font-weight-bold">
					管理者登録・更新	
				</v-card-subtitle>
				<v-spacer />
				                    <v-btn large class="ma-2 btn-edit" @click="clickSave">保存</v-btn>
                    <v-btn large class="ma-2 btn-close" @click="clickBack">戻る</v-btn>
			</v-system-bar>
		</v-card>
	</v-card >
	<v-card>
		<div style="width:800px" class="ma-5 pa-5">
		<v-simple-table class="plant-tbl">
			<colgroup>
			<col style="width:150px">
			</colgroup>
			<tr>
				<th>
					管理者CD
				</th>
				<td>
					<div style="width:150px">
					<v-text-field outlined dense 
						hide-details="auto"
						maxlength="10"
						type="tel"
						autofocus
                        :error-messages="err.admin_cd"
						v-model="adminInfo.admin_cd">
					</v-text-field>
					</div>
				</td>
			</tr>
			<tr>
				<th>
					管理者名
				</th>
				<td>
					<div style="width:400px">
					<v-text-field outlined dense 
						hide-details="auto"
                        :error-messages="err.admin_name"
						v-model="adminInfo.admin_name">
					</v-text-field>
					</div>
				</td>

			</tr>
			<tr>
				<th>
					メールアドレス	
				</th>
				<td>
					<div style="width:400px">
					<v-text-field outlined dense 
						hide-details="auto"
                        :error-messages="err.mailaddress"
						v-model="adminInfo.mailaddress">
					</v-text-field>
					</div>
				</td>

			</tr>
			<tr>
				<th>
				パスワード	
				</th>
				<td>
					<div style="width:200px">
					<v-text-field 
					:append-icon="showPass?'mdi-eye':'mdi-eye-off'"
					:type="showPass?'tel':'password'"
					@click:append="showPass=!showPass"
					outlined dense 
					autocomplete="new-password"
					maxlength="15"
						hide-details="auto"
                        :error-messages="err.password"
						v-model="adminInfo.password">
					</v-text-field>
					</div>
				</td>

			</tr>
		</v-simple-table>
		</div>

	</v-card>
	</v-container>
</template>
<script>
import { MESSAGE } from "../messages";
import {CONSTANT} from "../constant";
export default {
	data() {
		return {
			mode:'',
			showPass:false,
			adminInfo:{
				admin_id:'',
				admin_cd:'',
				admin_name:'',
				password:'',
				mailaddress:'',
				auth_kbn:'1',
			},
			err:{
				admin_id:'',
				admin_cd:'',
				admin_name:'',
				password:'',
				mailaddress:'',
			}	
		}
	},
	mounted(){
        if(this.$store.state.adminId !==''){
			this.mode = CONSTANT.MODE_UPDATE;
			this.adminInfo.admin_id =  this.$store.state.adminId;
			this.getAdmin();
		}
	},
	methods: {
		async getAdmin(){
			const res = await this.axios.post('get-admin-info',{admin_id:this.adminInfo.admin_id});
			if(res.data.resultCode === CONSTANT.RETURN_NORMAL){
				this.adminInfo.admin_id = res.data.resultData.adminInfo.admin_id;
				this.adminInfo.admin_cd = res.data.resultData.adminInfo.admin_cd;
				this.adminInfo.password = res.data.resultData.adminInfo.password;
				this.adminInfo.admin_name = res.data.resultData.adminInfo.admin_name;
				this.adminInfo.mailaddress = res.data.resultData.adminInfo.mailaddress;
				this.adminInfo.auth_kbn = res.data.resultData.adminInfo.auth_kbn;

			}
		},
        /**
         * 入力チェック
         */
        inputCheck()
        {
            //error clear
            this.clearError();


            if(!this.adminInfo.admin_cd){
                this.err.admin_cd = [MESSAGE.formatByArr(MESSAGE.E001,'管理者コード')];
            }
            if(!this.adminInfo.admin_name){
                this.err.admin_name = [MESSAGE.formatByArr(MESSAGE.E001,'管理者名')];
            }
            if(!this.adminInfo.mailaddress){
                this.err.mailaddress = [MESSAGE.formatByArr(MESSAGE.E001,'メールアドレス')];
            }
            if(!this.adminInfo.password){
                this.err.password = [MESSAGE.formatByArr(MESSAGE.E001,'パスワード')];
            }
            
            for (let key in this.err) {
                if (this.err[key].length > 0){
                    return true;
                }
            }
            return false;
        },
		/**
		 * 管理者情報の保存
		 */
		async clickSave(){

            // input check
            if(await this.inputCheck()){
                // in error
                this.swalAlert(MESSAGE.TITLE_ERROR, MESSAGE.E007);
                return;
            }

            // 確認メッセージ
            const msgres = await this.swalConfirm(
                MESSAGE.TITLE_CONFIRM,
                (this.mode=='i')?MESSAGE.R001:MESSAGE.R002);
            if(!msgres){
                return ;
            }


			const res = await this.axios.post('save-admin-info',this.adminInfo);
			if(res.data.resultCode==CONSTANT.RETURN_NORMAL){
                this.swalToast( MESSAGE.formatByArr(MESSAGE.I002,'管理者情報'));
                this.$store.commit('setAdminId', '');
                this.$router.push("/adminlist");	
			}
		},
		clickBack(){
            this.$store.commit('setAdminId', '');
            this.$router.push("/adminlist");
        
		},
        /**
         * エラーの一括クリア
         */
        clearError() {
            for (var key in this.err) {
                this.err[key] = [];
            }
        },

	},
}
</script>